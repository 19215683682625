import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from "react";
import { JobsFilterContext } from "../../contexts/JobsFilterContext";
import CheckBoxCard from "../CheckBoxCard";
import TimeCard from "../TimeCard";
import { Typo14, Typo18 } from "../Typography";
import {
  JobListFilterMainRow,
  JobSearchCloseImageWrapper,
  JobListFilterRoleTypeWrapper,
  JobListFilterLine,
  JobListFilterWrapper,
  JobListFilterInk,
  JobListButton,
  JobListButtonWrapper,
  JobListFilterMobileButtonWrapper,
  JobListFilterMobileButton,
  ActiveFiltersWrapper,
  ActiveFiltersWrapperDesktop,
} from "./styled";
import XIcon from "../../assets/svg/XIcon";
import ISelected from "../../interfaces/ISelected";
import useUpdate from "../../hooks/useUpdate";
import ActiveFiltersBadge from "../ActiveFiltersBadge";
import useWindowSize from "../../hooks/windowSize";

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const JobListFilter: React.FC<Props> = ({ visible, setVisible }) => {
  const {
    searched,
    setSearched,
    techs,
    stacks,
    filters: initialFilters,
    setFilters: dispatchChange,
    activeFilters,
  } = useContext(JobsFilterContext);

  const [changed, setChanged] = useState(false);

  const [openTab, setOpenTab] = useState<string | null>(null);

  const [focused, setFocused] = useState(false);

  const [filters, setFilters] = useState(initialFilters);

  const { width: windowWidth } = useWindowSize();

  const handleOutsideClick = useCallback(() => setFocused(false), [setFocused]);

  const handleMainRowClick = useCallback((e) => {
    e.stopPropagation();
    setFocused(true);
  }, []);

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick);

    return () => document.body.removeEventListener("click", handleOutsideClick);
  }, [handleOutsideClick]);

  useUpdate(() => {
    if (!focused) setOpenTab(null);
  }, [focused]);

  function onChange(
    name: "time" | "stacks" | "techs",
    selected: ISelected[] | (number | null)
  ) {
    const newFilters = { ...filters };

    if (name === "time") {
      newFilters.timezone = selected as number | null;
    } else {
      const currentSelected = selected as any[];

      newFilters[name] = currentSelected.filter((item: any) => item.selected);
    }

    setFilters(newFilters);

    if (windowWidth > 1024) {
      setChanged(false);

      dispatchChange(newFilters);
    } else {
      setChanged(true);
    }
  }

  function onMobileFilterClick(e: React.MouseEvent<HTMLButtonElement>) {
    setVisible(!visible);

    if (windowWidth >= 1440) return;

    if (visible) return;

    const target = e.currentTarget as HTMLElement;

    if (!target || !document.scrollingElement) return;

    const rect = target.getBoundingClientRect();

    const y = Math.max(0, document.scrollingElement.scrollTop + rect.y - 75);

    try {
      window.scrollTo({
        top: y,
        left: 0,
        behavior: "smooth",
      });
    } catch (e) {
      window.scrollTo(0, y);
    }
  }

  function onApply() {
    setOpenTab(null);

    setVisible(false);

    setChanged(false);

    dispatchChange(filters);
  }

  return (
    <>
      <JobListFilterMobileButtonWrapper>
        {searched ? (
          <JobListFilterMobileButton
            backgroundColor={"#f7f7f7"}
            onClick={() => setSearched(false)}
          >
            <JobSearchCloseImageWrapper>
              <XIcon />
            </JobSearchCloseImageWrapper>
          </JobListFilterMobileButton>
        ) : (
          <JobListFilterMobileButton
            background={visible}
            border={true}
            onClick={onMobileFilterClick}
          >
            <Typo14 color="#658AC8" fontWeight="500">
              FILTER
            </Typo14>
            <ActiveFiltersWrapper>
              <ActiveFiltersBadge activeFilters={activeFilters} />
            </ActiveFiltersWrapper>
          </JobListFilterMobileButton>
        )}
      </JobListFilterMobileButtonWrapper>
      <JobListFilterMainRow visible={visible} onClick={handleMainRowClick}>
        <JobListFilterInk showInk={openTab === "time"}>
          <JobListFilterWrapper tabletWidth={238} desktopWidth={258}>
            <JobListFilterRoleTypeWrapper>
              <CheckBoxCard
                label={"ROLE TYPE"}
                placeholder={"Choose type"}
                border={true}
                openTab={openTab}
                parentFocused={focused}
                setOpenTab={setOpenTab}
                items={stacks}
                name="stacks"
                onChange={(selected: ISelected[]) =>
                  onChange("stacks", selected)
                }
                desktopHideSearch={true}
                desktopHideArrow={true}
                desktopPaddingLeft={53}
                tabletPaddingLeft={33}
                discardBackgorund="#dedede"
                initialSelectedItems={initialFilters.stacks}
              />
            </JobListFilterRoleTypeWrapper>
          </JobListFilterWrapper>
          <JobListFilterWrapper tabletWidth={238} desktopWidth={258}>
            <TimeCard
              name="time"
              label={"TIMEZONE"}
              placeholder={"Choose timezone"}
              openTab={openTab}
              setOpenTab={setOpenTab}
              parentFocused={focused}
              desktopPaddingLeft={30}
              tabletPaddingLeft={25}
              onChange={(timezone: number | null) => onChange("time", timezone)}
              initialTimezone={filters.timezone}
            />
          </JobListFilterWrapper>
        </JobListFilterInk>
        <JobListFilterLine
          transparent={openTab === "time" || openTab === "techs"}
        />
        <JobListFilterWrapper tabletWidth={254} desktopWidth={284}>
          <CheckBoxCard
            label={"TECH STACK"}
            placeholder={"Choose languages"}
            border={false}
            openTab={openTab}
            parentFocused={focused}
            setOpenTab={setOpenTab}
            items={techs}
            name="techs"
            onChange={(selected: ISelected[]) => onChange("techs", selected)}
            showInk={openTab === "techs"}
            desktopPaddingLeft={30}
            tabletPaddingLeft={30}
            initialSelectedItems={initialFilters.techs}
          />
        </JobListFilterWrapper>
        <JobListButtonWrapper show={!openTab && changed}>
          <JobListButton onClick={onApply}>
            <Typo18 fontWeight="600" color="#fff" textAlign="center">
              <span>Apply</span>
              <span>Show Roles</span>
            </Typo18>
            <ActiveFiltersWrapperDesktop>
              <ActiveFiltersBadge activeFilters={activeFilters} />
            </ActiveFiltersWrapperDesktop>
          </JobListButton>
        </JobListButtonWrapper>
      </JobListFilterMainRow>
    </>
  );
};

export default JobListFilter;
