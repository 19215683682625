import React from "react";
import theme from "../../themes/theme";
import { SvgComponentProps } from "./../../definitions/Svg";

interface I extends SvgComponentProps {
  focused?: boolean;
}

const SearchIcon: React.FC<I> = (prosp) => {
  const focused = prosp.focused;

  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        opacity="0.5"
        stroke={focused ? theme.colors.watermelon : "#1B1B1B"}
        style={{ transition: "stroke 0.15s ease-out" }}
      >
        <path
          d="M6.875 11.875C9.63642 11.875 11.875 9.63642 11.875 6.875C11.875 4.11358 9.63642 1.875 6.875 1.875C4.11358 1.875 1.875 4.11358 1.875 6.875C1.875 9.63642 4.11358 11.875 6.875 11.875Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.125 13.125L10.4062 10.4062"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default SearchIcon;
