import React from "react";
import aEvent from "../../common/aEvent";
import IClientSuccess from "../../interfaces/IClientSuccess";
import ClientSuccess from "../ClientSuccess";
import SimpleSlider from "../SimpleSlider";
import WideSection from "../WideSection";
import { Wrapper } from "./styles";

interface IProps {
  clientSuccesses: IClientSuccess[] | null;
}

const clientSuccesses: React.FC<IProps> = ({ clientSuccesses }) => {
  return (
    <SimpleSlider
      padding={22}
      paddingDesktop={16}
      items={clientSuccesses}
      formatItems={true}
      interactionCallback={() => {
        aEvent({
          ec: "Slider Events",
          ea: "slider-interaction",
          el: "client-success",
          ni: false,
        });
      }}
      renderItem={(item: IClientSuccess) => {
        return <ClientSuccess key={item.id} clientSuccess={item} />;
      }}
    />
  );
};

export default clientSuccesses;
