import styled, { css } from 'styled-components';
import { media } from '../../common/MediaQueries';

export const JobListControlsStyled = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  position: relative;
  z-index: 1;

  @media ${media.tablet} {
    justify-content: center;
    margin-top: 20px;
  }
`;

export const JobListSearchWrapper = styled.div`
  flex-grow: 1;

  @media ${media.tablet} {
    display: none;
  }
`;

interface I {
  show: boolean;
}

export const JobListControlsOverlay = styled.div<I>`
  ${({ show }) =>
    !show
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : ''}

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.08);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media ${media.tablet} {
    display: none;
  }
`;
