import React from "react";
import NarrowSection from "../NarrowSection";

import { Typo14 } from "../Typography";
import { JobsListEmptyWrapper } from "./styles";

const JobListEmpty: React.FC = () => {
  return (
    <NarrowSection>
      <JobsListEmptyWrapper>
        <Typo14 textAlign="center">
          Looks like we dont have any jobs matching your criteria.
        </Typo14>
      </JobsListEmptyWrapper>
    </NarrowSection>
  );
};

export default JobListEmpty;
