import React, { ReactElement, useRef, useState } from "react";
import FilterButton from "../FilterButton";
import FilterList from "../FilterList";

import { CheckboxCardArrow, CheckboxPopup } from "./styles";
import ITechItem from "../../interfaces/ITechItem";
import FilterCardTopRow from "../FilterCardTopRow";
import ISelected from "../../interfaces/ISelected";
import useUpdate from "../../hooks/useUpdate";
import useWindowSize from "../../hooks/windowSize";
import IRoleType from "../../interfaces/IRoleType";

interface Props {
  label: string;
  placeholder: string;
  border: boolean;
  parentFocused: boolean;
  desktopHideSearch?: boolean;
  desktopHideArrow?: boolean;
  discardBackgorund?: string;
  showInk?: boolean;
  showCloseButton?: boolean;
  items: ITechItem[] | IRoleType[];
  name: string;
  openTab: string | null;
  setOpenTab: React.Dispatch<React.SetStateAction<string | null>>;
  onChange: Function;
  desktopPaddingLeft: number;
  tabletPaddingLeft: number;
  initialSelectedItems: ITechItem[] | IRoleType[];
}

export default function CheckBoxCard(props: Props): ReactElement {
  const {
    label,
    placeholder,
    border,
    desktopHideSearch,
    desktopHideArrow,
    openTab,
    setOpenTab,
    items,
    name,
    onChange: dispatchChange,
    showInk,
    desktopPaddingLeft,
    discardBackgorund,
    parentFocused,
    tabletPaddingLeft,
    initialSelectedItems,
  } = props;

  const initialSummary =
    initialSelectedItems.length > 0
      ? initialSelectedItems.map((item) => item.name).join(", ")
      : placeholder;

  const [summary, setSummary] = useState(initialSummary);

  const [isFiltering, setIsFiltering] = useState(
    initialSelectedItems.length > 0
  );

  const { width } = useWindowSize();

  const [selected, setSelected] = useState<ISelected[]>(
    items.map((item) => ({
      uid: item.uid,
      name: item.name,
      selected: initialSelectedItems.some(
        (selectedItem) => selectedItem.uid === item.uid
      ),
      aliases: item.aliases,
    }))
  );

  const saved = useRef(
    items.map((item) => ({
      uid: item.uid,
      selected: initialSelectedItems.some(
        (selectedItem) => selectedItem.uid === item.uid
      ),
    }))
  );

  function onChange(uid: string, isSelected: boolean) {
    const newSelected = [...selected];

    const item = newSelected.filter((item) => item.uid === uid)[0];

    const index = newSelected.indexOf(item);

    newSelected[index].selected = isSelected;

    setSelected(newSelected);

    if (width < 1025) return;

    save(newSelected);
  }

  function save(sel: ISelected[]) {
    const newSelected = [...sel];

    saved.current = newSelected.map((item) => {
      return {
        uid: item.uid,
        selected: item.selected,
      };
    });

    const newIsFiltering = newSelected.some((item) => item.selected);

    const chosen = newSelected.filter((item) => item.selected);

    const newSummary = chosen.map((item) => item.name).join(", ");

    setSummary(chosen.length === 0 ? placeholder : newSummary);

    setIsFiltering(newIsFiltering);

    dispatchChange(chosen);
  }

  useUpdate(() => {
    if (parentFocused) return;

    onDiscard();
  }, [parentFocused]);

  function onDiscard() {
    if (isTheSame()) return;

    const newSelected = selected.map((item) => {
      const savedItem = saved.current.filter(
        (savedItem) => savedItem.uid === item.uid
      )[0];

      return {
        ...item,
        selected: savedItem.selected,
      };
    });

    setSelected(newSelected);
  }

  function onClear() {
    const newSelected = selected.map((item) => ({
      ...item,
      selected: false,
    }));

    setSelected(newSelected);

    save(newSelected);
  }

  function isTheSame() {
    const isTheSame = selected.every((selectedItem) => {
      const { uid } = selectedItem;

      const savedItem = saved.current.filter(
        (savedItem) => savedItem.uid === uid
      )[0];

      return savedItem.selected === selectedItem.selected;
    });

    return isTheSame;
  }

  function onButtonClick() {
    const isClose = openTab === name;

    if (isClose) {
      setOpenTab(null);

      return;
    }

    setOpenTab(name);
  }

  function onBack() {
    onDiscard();
    setOpenTab(null);
  }

  return (
    <>
      <FilterButton
        label={label}
        summary={summary}
        border={border}
        onClick={onButtonClick}
        show={!openTab}
        showInk={showInk || false}
        showDiscard={isFiltering}
        desktopPaddingLeft={desktopPaddingLeft}
        tabletPaddingLeft={tabletPaddingLeft}
        onCloseClick={onClear}
        discardBackgorund={discardBackgorund}
      />
      <CheckboxPopup open={openTab === name}>
        <CheckboxCardArrow desktopHideArrow={desktopHideArrow} />
        <FilterCardTopRow placeholder={placeholder} onClose={onBack} />
        <FilterList
          desktopHideSearch={desktopHideSearch}
          selected={selected}
          onChange={onChange}
          onConfirm={() => {
            save([...selected]);
            setOpenTab(null);
          }}
        />
      </CheckboxPopup>
    </>
  );
}
