import React, { MouseEventHandler } from "react";
import BackArrow from "../../assets/svg/BackArrow";
import { Typo14 } from "../Typography";
import {
  JobListCheckboxFilterBackButton,
  JobListCheckboxFilterTopRow,
} from "./styles";

interface Props {
  placeholder: string;
  onClose: MouseEventHandler<HTMLButtonElement>;
}

const FilterCardTopRow: React.FC<Props> = (props) => {
  const { placeholder, onClose } = props;

  return (
    <JobListCheckboxFilterTopRow>
      <Typo14 color="#0B264C">{placeholder}</Typo14>
      <JobListCheckboxFilterBackButton onClick={onClose}>
        <BackArrow />
      </JobListCheckboxFilterBackButton>
    </JobListCheckboxFilterTopRow>
  );
};

export default FilterCardTopRow;
