import React from "react";
import {
  InformationCell,
  LocationCell,
  TechCell,
  TimeCell,
  TypeCell,
} from "../JobListCells";
import NarrowSection from "../NarrowSection";
import { Typo14 } from "../Typography";
import { JobListHeaderTable } from "./styles";

const JobListHeader = () => {
  return (
    <NarrowSection>
      <JobListHeaderTable>
        <thead>
          <tr>
            <InformationCell small={true}>
              <div style={{ paddingLeft: "6px" }}>
                <Typo14 letterSpacing="0" fontWeight="600">
                  ROLE INFORMATION
                </Typo14>
              </div>
            </InformationCell>
            <TypeCell small={true}>
              <Typo14 letterSpacing="0" fontWeight="600">
                TYPE
              </Typo14>
            </TypeCell>
            <TechCell small={true}>
              <Typo14 letterSpacing="0" fontWeight="600">
                TECH STACK
              </Typo14>
            </TechCell>
            <LocationCell small={true}>
              <Typo14 letterSpacing="0" fontWeight="600">
                COMPANY
              </Typo14>
            </LocationCell>
            <TimeCell small={true}>
              <Typo14 letterSpacing="0" fontWeight="600">
                LOCATION
              </Typo14>
            </TimeCell>
          </tr>
        </thead>
      </JobListHeaderTable>
    </NarrowSection>
  );
};

export default JobListHeader;
