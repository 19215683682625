import React, { MouseEventHandler } from "react";
import { Typo14 } from "../Typography";
import { FilterSaveButton, FilterSaveWrapper } from "./styles";

type Props = {
  onSave: Function;
};

const FilterSave: React.FC<Props> = (props) => {
  const { onSave } = props;

  return (
    <FilterSaveWrapper>
      <FilterSaveButton onClick={onSave as MouseEventHandler}>
        <Typo14 color="#fff" fontWeight="600" textAlign="center">
          Save
        </Typo14>
      </FilterSaveButton>
    </FilterSaveWrapper>
  );
};

export default FilterSave;
