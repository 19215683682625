import React, { useContext, Fragment } from "react";
import ClientSuccesseses from "../ClientSuccesses";
import JobListNew from "../JobListNew";
import { JobListOtherWrapper, Wrapper } from "./styles";
import {
  IJobsFilterContext,
  JobsFilterContext,
} from "../../contexts/JobsFilterContext";
import JobListEmpty from "../JobListEmpty";
import IClientSuccesses from "../../interfaces/IClientSuccess";
import JobListHeader from "../JobListHeader";
import NarrowSection from "../NarrowSection";
import JobListLoading from "../JobListLoading";
import ICaseStudy from "../../interfaces/ICaseStudy";
import CaseStudiesSliderSmall from "../CaseStudiesSliderSmall";

interface IProps {
  caseStudies: ICaseStudy[];
  clientSuccesses: IClientSuccesses[];
  showAll: boolean;
}

const JobsListWithClients: React.FC<IProps> = (props) => {
  const { caseStudies, clientSuccesses, showAll } = props;

  const { jobs, loading, filtered } = useContext<IJobsFilterContext>(
    JobsFilterContext
  );

  const shownJobs = jobs.reduce((acc, job) => `${acc}${job.uid}`, "");

  const jobsCount = jobs.length;

  if (loading) {
    return (
      <Wrapper>
        <JobListHeader />
        <NarrowSection>
          <JobListLoading />
        </NarrowSection>
      </Wrapper>
    );
  }

  if (jobsCount === 0) {
    return <JobListEmpty />;
  }

  if (jobsCount < 5) {
    return (
      <Wrapper>
        <JobListHeader />
        <NarrowSection>
          <JobListNew jobs={jobs} />
        </NarrowSection>
      </Wrapper>
    );
  }

  const testemonialsCount = caseStudies.length;
  const successCount = clientSuccesses.length;

  const otherPartsSize = 3;

  const testemonialsParts = Math.floor(testemonialsCount / otherPartsSize);
  const successesParts = Math.floor(successCount / otherPartsSize);

  const jobsPartSize = Math.max(
    jobsCount / (testemonialsParts + successesParts + 1),
    4
  );

  const jobsParts = Math.ceil(jobsCount / jobsPartSize);

  const otherItemsAggregated = new Array(testemonialsParts + successesParts)
    .fill(1)
    .reduce(
      (acc, _, index) => {
        const even = index % 2 === 0;

        const currentKey = even ? "testemonial" : "success";

        const otherKey = even ? "success" : "testemonial";

        const currentArray = even ? caseStudies : clientSuccesses;

        const otherArray = even ? clientSuccesses : caseStudies;

        const currentParts = even ? testemonialsParts : successesParts;

        const otherParts = even ? successesParts : testemonialsParts;

        if (acc[currentKey] < currentParts) {
          const items = currentArray.slice(
            acc[currentKey] * otherPartsSize,
            (acc[currentKey] + 1) * otherPartsSize
          );
          acc.lists.push({
            type: currentKey,
            items,
          });
          acc[currentKey] = acc[currentKey] + 1;
        } else if (acc[otherKey] < otherParts) {
          const items = otherArray.slice(
            acc[otherKey] * otherPartsSize,
            (acc[otherKey] + 1) * otherPartsSize
          );
          acc.lists.push({
            type: otherKey,
            items,
          });
          acc[otherKey] = acc[otherKey] + 1;
        }

        return acc;
      },
      { testemonial: 0, success: 0, lists: [] }
    );

  const showingParts = showAll ? jobsParts : Math.min(jobsParts, 2);

  return (
    <Wrapper>
      <JobListHeader />
      {new Array(showingParts).fill(1).map((_, index) => {
        const nextIndex = index + 1;

        const jobsForPart = jobs.slice(
          index * jobsPartSize,
          nextIndex * jobsPartSize
        );

        const otherItemsList = otherItemsAggregated.lists[index];

        let otherItemsComponent;

        if (!otherItemsList || index === jobsParts - 1) {
          otherItemsComponent = null;
        } else if (otherItemsList.type === "testemonial") {
          otherItemsComponent = (
            <JobListOtherWrapper key={`${shownJobs}${index}`}>
              <CaseStudiesSliderSmall
                isJobs={true}
                caseStudies={otherItemsList.items}
              />
            </JobListOtherWrapper>
          );
        } else {
          otherItemsComponent = (
            <JobListOtherWrapper key={`${shownJobs}${index}`}>
              <ClientSuccesseses clientSuccesses={otherItemsList.items} />
            </JobListOtherWrapper>
          );
        }

        return (
          <Fragment key={`${shownJobs}${index}`}>
            <NarrowSection>
              <JobListNew jobs={jobsForPart} />
            </NarrowSection>
            {otherItemsComponent}
          </Fragment>
        );
      })}
    </Wrapper>
  );
};

export default JobsListWithClients;
