import styled from 'styled-components';

import { media } from '../../common/MediaQueries';

export const Wrapper = styled.div`
  min-height: 80vh;
  padding-top: 5px;

  @media ${media.tablet} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const JobListOtherWrapper = styled.div`
  width: 100%;
  max-width: 1596px;
  margin: 0 auto;
  padding: 40px 0 20px 0;

  @media ${media.desktop} {
    padding: 28px 0 16px 0;
  }
`;
