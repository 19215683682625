import IJob from '../interfaces/IJob';
import IRoleType from '../interfaces/IRoleType';

export default function getUniqueStacks(jobs: IJob[]): IRoleType[] {
  const uniqueItems = jobs.reduce((acc, job) => {
    const { roleTypes } = job;

    const uniqueRoleTypes = roleTypes.filter(
      roleType => !acc.some(accRoleType => accRoleType.uid === roleType.uid)
    );

    return acc.concat(uniqueRoleTypes);
  }, [] as IRoleType[]);

  return uniqueItems.sort((a, b) =>
    a.name.trim().slice(0, 1).toLowerCase() > b.name.trim().slice(0, 1).toLowerCase() ? 1 : -1
  );
}
