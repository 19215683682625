import React, { ReactElement, useState } from "react";
import ISelected from "../../interfaces/ISelected";
import FilterSave from "../FilterSave";
import { StatelessSearchInput } from "../SearchInput";
import { Typo14 } from "../Typography";
import {
  CheckboxListCheckbox,
  CheckboxListItem,
  CheckboxListItemsWrapper,
  CheckboxListLabel,
  CheckboxListWrapper,
  ListEmpty,
} from "./styles";

interface Props {
  selected: ISelected[];
  onChange: Function;
  onConfirm: Function;
  desktopHideSearch?: boolean;
}

export default function FilterList(props: Props): ReactElement {
  const { selected, onChange, onConfirm, desktopHideSearch } = props;

  const [search, setSearch] = useState("");

  const formattedSearch = search.toLowerCase().trim();

  const decoratedItems = selected.map((item) => {
    const { name, aliases } = item;

    const show = name.toLowerCase().includes(formattedSearch);

    const showByAlias = aliases
      ? aliases.filter((alias) => alias.toLowerCase().includes(formattedSearch))
          .length > 0
      : false;

    return {
      ...item,
      show: show || showByAlias,
    };
  });

  const hasItems = decoratedItems.some((item) => item.show);

  return (
    <CheckboxListWrapper desktopHideSearch={desktopHideSearch}>
      <StatelessSearchInput
        square
        desktopHide={desktopHideSearch}
        onChange={(value: string) => setSearch(value)}
        value={search}
      />
      {!hasItems ? (
        <ListEmpty>
          <span>No Result</span>
        </ListEmpty>
      ) : (
        <CheckboxListItemsWrapper desktopHideSearch={desktopHideSearch}>
          {decoratedItems.map((item) => {
            const { uid, name, show } = item;

            const stateItem = selected.filter((item) => item.uid === uid)[0];

            const { selected: isSelected } = stateItem;

            return (
              <CheckboxListItem key={uid} show={show}>
                <CheckboxListCheckbox
                  id={`filter-checkbox-${uid}`}
                  name={uid}
                  type="checkbox"
                  onChange={(e) => onChange(uid, e.currentTarget.checked)}
                  checked={isSelected}
                />
                <CheckboxListLabel htmlFor={`filter-checkbox-${uid}`}>
                  <Typo14 color="#0B264C" style={{ pointerEvents: "none" }}>
                    {name}
                  </Typo14>
                </CheckboxListLabel>
              </CheckboxListItem>
            );
          })}
        </CheckboxListItemsWrapper>
      )}
      <FilterSave onSave={onConfirm} />
    </CheckboxListWrapper>
  );
}
