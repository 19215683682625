import styled from 'styled-components';
import { media } from '../../common/MediaQueries';

interface I {
  show: boolean;
}

export const TimezoneFilterWrapper = styled.div<I>`
  ${({ show }) => (show ? '' : 'display:none;')}

  background: #fff;
  padding-bottom: 31px;

  @media ${media.tablet} {
    position: absolute;
    height: auto;
    width: 563px;
    top: calc(100% + 8px);
    left: 50%;
    box-shadow:
      -4px 0px 22px rgba(0, 0, 0, 0.02),
      9px 0px 28px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    transform: translate(-50%, 0);
    padding-bottom: 22px;
  }
`;

export const TimezoneFilterArrow = styled.div`
  display: none;

  @media ${media.tablet} {
    display: block;
    position: absolute;
    width: 21px;
    height: 21px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: #fff;
    box-shadow:
      -4px 0px 22px rgba(0, 0, 0, 0.02),
      9px 0px 28px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
`;

export const TimezoneFitlerContentWrapper = styled.div`
  position: relative;
  padding: 14px 14px 0 14px;
  background: #fff;

  @media ${media.tablet} {
    padding-top: 22px;
    border-radius: 15px;
  }
`;

export const TimezoneSelectCurrent = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding: 9px 5px;
  border-radius: 4px;
  background: #658ac8;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;

  &:hover,
  &:focus,
  &:active {
    background: #5e80b8;
    outline: none;
  }

  @media ${media.phone} {
    max-width: 511px;
  }

  @media ${media.tablet} {
    margin-top: 0;
  }
`;

export const TimezoneFitlerScaleWrapper = styled.div`
  margin-top: 20px;
  height: 46px;
  max-width: 292px;
  margin-left: auto;
  margin-right: auto;

  @media ${media.phone} {
    max-width: 511px;
  }
`;

export const TimezoneFilterBoxes = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 12px;
  align-items: center;

  @media ${media.tablet} {
    padding-left: 15px;
    justify-content: flex-start;
  }
`;

export const TimezoneFilterText = styled.div`
  display: block;
  letter-spacing: -0.01em;
  padding-right: 10px;

  @media ${media.tablet} {
    padding-right: 1px;
  }
`;

export const TimezoneFilterBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 41px;
  background: #f7f7f7;
  border-radius: 2px;
  color: #658ac8;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;

  @media ${media.tablet} {
    height: 22px;
    width: 32px;
    background: transparent;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const TimezoneFilterTo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.01em;
  font-variant: small-caps;
  color: #658ac8;

  @media ${media.tablet} {
    display: block;
    width: auto;
    font-size: 16px;
    line-height: 22px;
    font-variant: normal;
  }
`;
