import React, { useEffect, useRef, useState, MouseEvent } from "react";
import ScaleBig from "../../assets/svg/ScaleBig";
import ScaleDesktop from "../../assets/svg/ScaleDesktop";
import ScaleSmall from "../../assets/svg/ScaleSmall";
import {
  hourToIntex,
  indexToHour,
  indexToPercent,
  pixelToIndex,
} from "../../common/utils";
import useWindowSize from "../../hooks/windowSize";

import {
  ScaleDriver,
  ScaleFilterNumbersWrapper,
  ScaleFilterScaleLine,
  ScaleFilterScaleWrapper,
  ScaleLine,
  ScaleMinHeight,
  ScaleNumber,
  ScalePadding,
  ScaleTouchWrapper,
} from "./styles";

interface Props {
  timezone: number | null;
  hintTimezone: number | null;
  show: boolean;
  onChange: Function;
  onEnd: Function;
}

export const Scale: React.FC<Props> = (props) => {
  const { timezone, hintTimezone, show, onChange, onEnd } = props;

  const hours = [-12, -10, -8, -6, -4, -2, 0, 2, 4, 6, 8, 10, 12];

  const wrapper = useRef<HTMLDivElement>(null);

  const [offset, setOffset] = useState(0);

  const [started, setStarted] = useState(false);

  const [rect, setRect] = useState<DOMRect | null>(null);

  const index = timezone === null ? null : hourToIntex(timezone, offset);

  const percent = index === null ? null : `${indexToPercent(index)}%`;

  const hintIndex =
    hintTimezone === null ? null : hourToIntex(hintTimezone, offset);

  const hintPercent =
    hintIndex === null ? null : `${indexToPercent(hintIndex)}%`;

  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    if (!show || !windowWidth || !wrapper.current) return;

    const rect = wrapper.current.getBoundingClientRect();

    setRect(rect);
  }, [show, windowWidth, wrapper]);

  function start(clientX: number) {
    if (!rect) return;

    const { left, width } = rect;

    const x = clientX - left;

    const newIndex = pixelToIndex(x, width);

    if (newIndex === index) return;

    dispatch(newIndex);

    setStarted(true);
  }

  function move(clientX: number) {
    if (!started || !rect) return;

    const { left, width } = rect;

    const x = clientX - left;

    const newIndex = pixelToIndex(x, width);

    if (newIndex === index) return;

    dispatch(newIndex);
  }

  function end() {
    if (!started) return;

    setStarted(false);

    onEnd();
  }

  function dispatch(newIndex: number) {
    onChange(indexToHour(newIndex, offset));
  }

  return (
    <ScalePadding>
      <ScaleTouchWrapper
        ref={wrapper}
        onMouseDown={(e) => start(e.clientX)}
        onTouchStart={(e) => start(e.touches[0].clientX)}
        onMouseMove={(e) => move(e.clientX)}
        onTouchMove={(е) => move(е.touches[0].clientX)}
        onMouseUp={end}
        onMouseLeave={end}
        onTouchEnd={end}
      >
        <ScaleMinHeight>
          <ScaleFilterScaleLine />
          <ScaleFilterScaleWrapper>
            <ScaleBig width={rect ? rect.width : 284} />
            <ScaleSmall width={rect ? rect.width : 284} />
            <ScaleDesktop />
          </ScaleFilterScaleWrapper>
          {(percent !== null || hintIndex !== null) && (
            <ScaleDriver
              isHint={percent === null}
              isAnimationPlaying={show}
              style={{ left: percent || hintPercent || "0%" }}
            />
          )}
        </ScaleMinHeight>
        <ScaleFilterNumbersWrapper>
          {hours.map((hour, index) => {
            return (
              <ScaleNumber key={hour} style={{ left: `${index * 8.333}%` }}>
                {hour > 0 ? `+${hour}` : hour}
              </ScaleNumber>
            );
          })}
        </ScaleFilterNumbersWrapper>
      </ScaleTouchWrapper>
    </ScalePadding>
  );
};

export default Scale;
