import styled from 'styled-components';
import { media } from '../../common/MediaQueries';

export const FilterButtonGroup = styled.div`
  position: relative;
  overflow: hidden;
`;

interface IFilterButton {
  show: boolean;
  desktopPaddingLeft: number;
  tabletPaddingLeft: number;
}

export const FilterButton = styled.button<IFilterButton>`
  ${({ show }) => (!show ? 'display:none;' : '')}
  width: 100%;
  position: relative;
  padding-left: 15px;
  padding-right: 25px;
  appearance: none;

  @media ${media.tablet} {
    display: block;
    padding: 12px 20px 19px ${({ tabletPaddingLeft }) => tabletPaddingLeft}px;
    border-radius: 100px;

    &:focus {
      outline: #658ac8 solid 2px;
      outline-offset: -2px;
    }
  }

  @media ${media.desktop} {
    padding: 12px 20px 19px ${({ desktopPaddingLeft }) => desktopPaddingLeft}px;
  }
`;

interface ICheckboxFilterWrapper {
  border: boolean;
}

export const FilterButtonWrapper = styled.span<ICheckboxFilterWrapper>`
  display: block;
  position: relative;
  margin-top: 10px;
  padding-bottom: 18px;
  border: none;
  width: 100%;
  cursor: pointer;
  ${({ border }) => (border ? 'border-bottom: 1px solid rgba(11, 38, 76, .1);' : '')}
  font-weight: 500;

  @media ${media.tablet} {
    padding: 0;
    padding-left: 15px;
    padding-right: 25px;
    border-bottom: none;
    margin: 0;
    font-weight: 600;
  }
`;

interface IFilterButtonInk {
  show: boolean;
}

export const FilterButtonInk = styled.span<IFilterButtonInk>`
  display: none;

  @media ${media.tablet} {
    ${({ show }) => (show ? 'display: block;' : '')}

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background: #eaedf1;
  }
`;

export const FilterButtonPlaceholder = styled.span`
  display: block;
  margin-top: 5px;
  color: rgba(11, 38, 76, 0.5);
  font-weight: 400;

  & > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media ${media.tablet} {
    margin-top: 8px;
    color: rgba(11, 38, 76, 0.6);
  }
`;

interface IFilterButtonCloseButton {
  show?: boolean;
  discardBackgorund?: string;
}

export const FilterButtonCloseButton = styled.button<IFilterButtonCloseButton>`
  ${({ show }) => (show ? 'display:block;' : 'display: none;')}

  position: absolute;
  top: 0;
  bottom: 0;
  width: 25px;
  height: 25px;
  right: 15px;
  margin: auto 0;
  border-radius: 50%;
  background-color: rgba(27, 27, 27, 0.08);
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgOSA5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMSAxTDggOCIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGQ9Ik04IDFMMSA4IiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==');
  background-repeat: no-repeat;
  background-position: 50%;
  appearance: none;

  &:active,
  &:focus {
    outline: #658ac8 solid 2px;
  }

  @media ${media.tablet} {
    right: 14px;
    width: 21px;
    height: 21px;
    background-color: ${({ discardBackgorund }) =>
      discardBackgorund ? discardBackgorund : '#fff'};
  }
`;
