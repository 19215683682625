import React, { useEffect, useRef, useState } from "react";
import useUpdate from "../../hooks/useUpdate";
import useWindowSize from "../../hooks/windowSize";

import FilterButton from "../FilterButton";
import TimezoneSimple from "../TimezoneSimple";

interface Props {
  name: string;
  label: string;
  placeholder: string;
  openTab: string | null;
  setOpenTab: React.Dispatch<React.SetStateAction<string | null>>;
  desktopPaddingLeft: number;
  tabletPaddingLeft: number;
  onChange: Function;
  parentFocused: boolean;
  initialTimezone: number | null;
}

const TimeCard: React.FC<Props> = (props) => {
  const {
    name,
    label,
    placeholder,
    openTab,
    setOpenTab,
    desktopPaddingLeft,
    onChange: dispatchChange,
    tabletPaddingLeft,
    parentFocused,
    initialTimezone,
  } = props;

  const [timezone, setTimezone] = useState(initialTimezone);

  const saved = useRef(initialTimezone);

  const { width: windowWidth } = useWindowSize();

  useUpdate(() => {
    if (parentFocused) return;

    const savedTimezone = saved.current;

    if (savedTimezone === timezone) return;

    setTimezone(savedTimezone);
  }, [parentFocused]);

  function onBackArrowClick() {
    const savedTimezone = saved.current;

    setTimezone(savedTimezone);

    setOpenTab((openTab) => (openTab === name ? null : name));
  }

  function onCloseClick() {
    setTimezone(null);

    notify(null);

    setOpenTab(null);
  }

  function onScaleTouchEnd() {
    if (windowWidth < 1025) return;

    notify(timezone);
  }

  function onSelectMyTimezoneClick(newTimezone: number) {
    setTimezone(newTimezone);

    if (windowWidth < 1025) return;

    notify(newTimezone);

    setOpenTab(null);
  }

  function onConfirm() {
    notify(timezone);

    setOpenTab(null);
  }

  function notify(newTimezone: number | null) {
    if (newTimezone === saved.current) return;

    saved.current = newTimezone;

    dispatchChange(newTimezone);
  }

  let summary;

  if (timezone === null) {
    summary = placeholder;
  } else if (timezone === 0) {
    summary = "GMT";
  } else {
    summary = `GMT${timezone > 0 ? "+" : ""}${timezone}`;
  }

  return (
    <>
      <FilterButton
        label={label}
        summary={summary}
        border={true}
        onClick={() =>
          setOpenTab((openTab) => (openTab === name ? null : name))
        }
        show={!openTab}
        showInk={false}
        showDiscard={timezone !== null}
        desktopPaddingLeft={desktopPaddingLeft}
        tabletPaddingLeft={tabletPaddingLeft}
        onCloseClick={onCloseClick}
      />
      <TimezoneSimple
        show={openTab === "time"}
        setOpenTab={setOpenTab}
        label="Select timezone"
        onBackArrowClick={onBackArrowClick}
        timezone={timezone}
        onChange={(timezone: number) => setTimezone(timezone)}
        onSelectMyTimezoneClick={onSelectMyTimezoneClick}
        onScaleTouchEnd={onScaleTouchEnd}
        onConfirm={onConfirm}
      />
    </>
  );
};

export default TimeCard;
