import IJob from '../interfaces/IJob';
import ITechItem from '../interfaces/ITechItem';

export default function getUniqueTechItems(jobs: IJob[]): ITechItem[] {
  const uniqueItems = jobs.reduce((acc, job) => {
    const { mustHaveTechItems, niceToHaveTechItems } = job;

    const allTechItems = [...mustHaveTechItems, ...niceToHaveTechItems];

    const allUniqueItems = allTechItems.filter(
      (item, index) =>
        allTechItems.indexOf(allTechItems.filter(innerItem => innerItem.uid === item.uid)[0]) ===
        index
    );

    const newItems = allUniqueItems.filter(item => !acc.some(accItem => accItem.uid === item.uid));

    return acc.concat(newItems);
  }, [] as ITechItem[]);

  return uniqueItems.sort((a, b) =>
    a.name.trim().slice(0, 1).toLowerCase() > b.name.trim().slice(0, 1).toLowerCase() ? 1 : -1
  );
}
