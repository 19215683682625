import React, { useState } from "react";

import SearchIcon from "../../assets/svg/SearchIcon";
import useUpdate from "../../hooks/useUpdate";

import {
  SearchInputWrapper,
  SearchInputInputWrapper,
  SearchInputStyled,
  SearchInputIconWrapper,
} from "./styles";

interface Props {
  square?: boolean;
  initialValue: string;
  desktopHide?: boolean;
  onChange: Function;
}

const SearchInput: React.FC<Props> = (props) => {
  const { initialValue, onChange: dispatchChange } = props;

  const [value, setValue] = useState(initialValue);

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;

    setValue(value);
  }

  useUpdate(() => {
    dispatchChange(value);
  }, [value]);

  return <StatelessSearchInput {...props} value={value} onChange={onChange} />;
};

interface IStatelessSearchInput {
  square?: boolean;
  value?: string;
  desktopHide?: boolean;
  onChange: Function;
}

export const StatelessSearchInput: React.FC<IStatelessSearchInput> = (
  props
) => {
  const { onChange, square = false, value, desktopHide = false } = props;

  const [focused, setFocused] = useState(false);

  return (
    <SearchInputWrapper desktopHide={desktopHide}>
      <SearchInputInputWrapper>
        <SearchInputStyled
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          square={square}
        />
        <SearchInputIconWrapper>
          <SearchIcon focused={focused} />
        </SearchInputIconWrapper>
      </SearchInputInputWrapper>
    </SearchInputWrapper>
  );
};

export default SearchInput;
