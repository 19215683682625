import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import aEvent from "../common/aEvent";
import getUniqueStacks from "../common/getUniqueStacks";
import getUniqueTechItems from "../common/getUniqueTechItems";
import intersectJobs from "../common/intersectJobs";
import stackFilterFunciton from "../common/stackFilterFunction";
import techFilterFunciton from "../common/techFilterFunction";
import timeFilterFunction from "../common/timeFilterFunction";
import useUpdate from "../hooks/useUpdate";
import IFilters from "../interfaces/IFilters";

import IJob from "../interfaces/IJob";
import IRoleType from "../interfaces/IRoleType";
import ITechItem from "../interfaces/ITechItem";

export interface IJobsFilterContext {
  initialJobs: IJob[];
  jobs: IJob[];
  searched: boolean;
  loading: boolean;
  filtered: boolean;
  queryEvaluated: boolean;
  activeFilters: number;
  setJobs: React.Dispatch<React.SetStateAction<IJob[]>>;
  setSearched: React.Dispatch<React.SetStateAction<boolean>>;
  setFilters: React.Dispatch<React.SetStateAction<IFilters>>;
  filters: IFilters;
  techs: ITechItem[];
  stacks: IRoleType[];
}

const initial = {
  initialJobs: [],
  jobs: [],
  searched: false,
  loading: true,
  filtered: false,
  queryEvaluated: true,
  activeFilters: 0,
  filters: {
    techs: [],
    stacks: [],
    timezone: null,
  },
  setSearched: () => {},
  setJobs: () => {},
  setFilters: () => {},
  techs: [],
  stacks: [],
};

export const JobsFilterContext = createContext<IJobsFilterContext>(initial);

interface I {
  jobs: IJob[];
  filtered: boolean;
}

const JobsFilterContextProvider: React.FC<I> = ({
  children,
  jobs: initialJobs,
  filtered,
}) => {
  const [jobs, setJobs] = useState(initialJobs);

  const [queryEvaluated, setQueryEvaluated] = useState(!filtered);

  const [searched, setSearched] = useState(false);

  const [loading, setLoading] = useState(filtered);

  const [filters, setFilters] = useState<IFilters>({
    techs: [],
    stacks: [],
    timezone: null,
  });

  const techEventFired = useRef<string[]>([]);
  const stackEventFired = useRef<string[]>([]);
  const timezoneEventsFired = useRef<number[]>([]);

  const activeFilters =
    (filters.techs.length > 0 ? 1 : 0) +
    (filters.stacks.length > 0 ? 1 : 0) +
    (filters.timezone !== null ? 1 : 0);

  const timeout = useRef<any>(null);

  const techs = useMemo(() => getUniqueTechItems(initialJobs), [initialJobs]);

  const stacks = useMemo(() => getUniqueStacks(initialJobs), [initialJobs]);

  function handleJobsUpdate(jobs: React.SetStateAction<IJob[]>) {
    setLoading(true);

    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setLoading(false);
    }, 1000);

    setJobs(jobs);
  }

  useEffect(() => {
    if (!filtered) return;

    const string = location.search?.substring(1);

    if (!string) {
      setLoading(false);
      setQueryEvaluated(true);
      return;
    }

    const search = JSON.parse(
      '{"' +
        decodeURI(string)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}'
    );

    const stackUids = (search.stack || "").split(",");
    const techUids = (search.tech || "").split(",");

    const queryTimezone = parseInt(search.timezone);

    const queryStacks = stacks.filter(
      (stack) => stackUids.indexOf(stack.uid) !== -1
    );
    const queryTechs = techs.filter(
      (tech) => techUids.indexOf(tech.uid) !== -1
    );

    const timesAreValid =
      !isNaN(queryTimezone) && queryTimezone < 13 && queryTimezone > -13;

    setFilters({
      stacks: queryStacks,
      techs: queryTechs,
      timezone: timesAreValid ? queryTimezone : null,
    });
  }, [filtered]);

  useUpdate(() => {
    const { stacks, techs, timezone } = filters;

    const stackJobs =
      stacks.length > 0
        ? initialJobs.filter((job) => stackFilterFunciton(job, stacks))
        : initialJobs;
    const techJobs =
      techs.length > 0
        ? initialJobs.filter((job) => techFilterFunciton(job, techs))
        : initialJobs;
    const timeJobs = initialJobs.filter((job) =>
      timeFilterFunction(job, timezone)
    );

    const jobs = intersectJobs([stackJobs, techJobs, timeJobs]);

    setJobs(jobs);
    setLoading(true);
  }, [filters]);

  useUpdate(() => {
    const { techs, stacks, timezone } = filters;

    const timezoneIsValid =
      timezone !== null && timezone < 13 && timezone > -13;

    const techsQuery =
      techs.length > 0 ? `tech=${techs.map((item) => item.uid).join(",")}` : "";
    const stacksQuery =
      stacks.length > 0
        ? `stack=${stacks.map((item) => item.uid).join(",")}`
        : "";
    const timezoneQuery = timezoneIsValid ? `timezone=${timezone}` : "";

    const items = [techsQuery, stacksQuery, timezoneQuery];

    const validItems = items.filter((item) => item);

    const query = validItems.join("&");

    const search = query ? `?${query}` : "";

    history.replaceState({}, "", `/jobs/filtered/${search}`);

    if (!queryEvaluated) return;

    techs.forEach((tech) => {
      if (techEventFired.current.indexOf(tech.uid) !== -1) return;

      techEventFired.current.push(tech.uid);

      aEvent({
        ec: "Job List Filter",
        ea: "filter-techs",
        el: tech.uid,
        ni: false,
      });
    });

    stacks.forEach((stack) => {
      if (stackEventFired.current.indexOf(stack.uid) !== -1) return;

      stackEventFired.current.push(stack.uid);

      aEvent({
        ec: "Job List Filter",
        ea: "filter-stacks",
        el: stack.uid,
        ni: false,
      });
    });

    if (
      timezoneIsValid &&
      timezoneEventsFired.current.indexOf(timezone) === -1
    ) {
      timezoneEventsFired.current.push(timezone);

      aEvent({
        ec: "Job List Filter",
        ea: "filter-timezone",
        el: `${timezone}`,
        ni: false,
      });
    }
  }, [filters]);

  useUpdate(() => {
    clearTimeout(timeout.current);

    timeout.current = setTimeout(() => {
      setLoading(false);
    }, 200 + Math.random() * 200);

    if (!queryEvaluated) {
      setQueryEvaluated(true);
    }
  }, [jobs]);

  const value: IJobsFilterContext = {
    initialJobs,
    jobs,
    searched,
    queryEvaluated,
    activeFilters,
    filters,
    filtered,
    setJobs: handleJobsUpdate,
    setSearched,
    setFilters,
    loading,
    techs,
    stacks,
  };

  return (
    <JobsFilterContext.Provider value={value}>
      {children}
    </JobsFilterContext.Provider>
  );
};

export default JobsFilterContextProvider;
