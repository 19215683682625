import React from "react";
import ISeo from "../../interfaces/ISeo";
import SEO from "../Seo";

type Props = {
  seo: ISeo;
  filtered: boolean;
};

const JobsSEO: React.FC<Props> = (props) => {
  const { filtered, seo } = props;

  const { title, description, author, image } = seo;

  const imagePath =
    image?.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src ||
    image?.localFile?.publicURL ||
    null;

  const imageUrl = imagePath
    ? `https://${process.env.GATSBY_PUBLIC_DOMAIN}${imagePath}`
    : "";

  return (
    <SEO
      title={title}
      description={description}
      meta={
        author
          ? [
              {
                name: `article:author`,
                content: author,
              },
            ]
          : []
      }
      image={imageUrl}
      url={`/jobs${filtered ? '/filtered' : ''}/`}
    />
  );
};

export default JobsSEO;
