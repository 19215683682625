import React from "react";
import { SvgComponent } from "./../../definitions/Svg";

const XIcon: SvgComponent = (props) => (
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L8 8" stroke="currentColor" strokeLinecap="round" />
    <path d="M8 1L1 8" stroke="currentColor" strokeLinecap="round" />
  </svg>
);

export default XIcon;
