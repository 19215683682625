import React, { useEffect, useRef, useState } from "react";
import useFocus from "../../hooks/useFocus";
import useUnloaded from "../../hooks/useUnloaded";
import ApplyButton from "../ApplyButton";
import { Typo14, Typo22, Typo25 } from "../Typography";
import {
  ContactButtonWrapper,
  ContactCard,
  ContactError,
  ContactFields,
  ContactHeading,
  ContactInput,
  ContactInputWrapper,
  ContactLabel,
  ContactRow,
  ContactSuccess,
  ContactTextarea,
  ContactWrapper,
} from "./style";

const urlPattern = new RegExp(
  "^s*?((?:http://)|(?:https://))?(www.)?((?:github.com)|(?:linkedin.com))([/a-zA-Z0-9._-]*)s*?$",
  "i"
);
const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
const stringInvalidPattern = /(?:^\s+$)|(?:^$)/i;

const fields = [
  {
    name: "firstName",
    label: "First Name *",
    validate: (val: string) => !stringInvalidPattern.test(val),
    invalidMessage: "Please provide non-empty name",
  },
  {
    name: "lastName",
    label: "Last Name *",
    validate: (val: string) => !stringInvalidPattern.test(val),
    invalidMessage: "Please provide non-empty name",
  },
  {
    name: "email",
    label: "Email *",
    validate: (val: string) => emailPattern.test(val.toLowerCase()),
    invalidMessage: "Invalid email",
  },
  {
    name: "url",
    label: "Linkedin / github",
    validate: (val: string) => val === "" || urlPattern.test(val),
    invalidMessage: "Invalid url",
  },
];

interface IStatus {
  loading?: boolean;
  success?: boolean;
  error?: boolean;
  throttled?: boolean;
}

interface Props {
  showAll?: boolean;
  extraMargin?: boolean;
}

const ContactForm: React.FC<Props> = (props) => {
  const { showAll, extraMargin } = props;

  const [status, setStatus] = useState<IStatus>({ loading: false });

  const cardElement = useRef<HTMLDivElement | null>(null);

  const [info, setInfo] = useState(
    fields
      .map((field) => ({
        name: field.name,
        value: "",
        error: field.name === "url" ? false : true,
        displayError: true,
        hasInteracted: false,
        validate: field.validate,
        invalidMessage: field.invalidMessage,
      }))
      .concat([
        {
          name: "message",
          value: "",
          error: true,
          displayError: true,
          hasInteracted: false,
          validate: (val: string) => !stringInvalidPattern.test(val),
          invalidMessage: "Provide non-empty message",
        },
      ])
  );

  useFocus({
    ref: cardElement,
    showAll,
    selector: "#section-job-form",
  });

  const valid = info.every((item) => !item.error);

  function onChange(name: string, value: string) {
    const newInfo = [...info];

    const item = newInfo.filter((item) => item.name === name)[0];

    const index = newInfo.indexOf(item);

    const error = !item.validate(value);

    const newItem = {
      ...item,
      value,
      error,
    };

    newInfo[index] = newItem;

    setInfo(newInfo);
  }

  function onFocus(name: string) {
    setInfo((latestInfo) => {
      const newInfo = [...latestInfo];

      const item = newInfo.filter((item) => item.name === name)[0];

      const index = newInfo.indexOf(item);

      item.displayError = false;

      newInfo[index] = item;

      return newInfo;
    });
  }

  function onBlur(name: string) {
    setInfo((latestInfo) => {
      const newInfo = [...latestInfo];

      const item = newInfo.filter((item) => item.name === name)[0];

      const index = newInfo.indexOf(item);

      const valid = item.validate(item.value);

      const newItem = {
        ...item,
        displayError: !valid,
        hasInteracted: true,
      };

      newInfo[index] = newItem;

      return newInfo;
    });
  }

  const unloaded = useUnloaded();

  async function onSubmit() {
    try {
      if (!valid) return;

      const payload = info.reduce((acc, info) => {
        const { name, value } = info;

        acc[name] = value;

        return acc;
      }, {} as any);

      const timezoneOffset = new Date().getTimezoneOffset();

      const timezoneFormatted = ((-1 * timezoneOffset) / 60)
        .toFixed(1)
        .replace(".0", "");

      const timezoneString =
        -1 * timezoneOffset > 0
          ? `GMT+${timezoneFormatted}`
          : `GMT${timezoneFormatted}`;

      payload.timezone = timezoneString;

      setStatus({ loading: true });

      const res = await fetch(
        `${process.env.GATSBY_API_URL}/content-api/requests/`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (unloaded) return;

      if (res.status > 199 && res.status < 300) {
        setStatus({ success: true });
      } else if (res.status === 429) {
        setStatus({ throttled: true });
      } else {
        setStatus({ error: true });
      }
    } catch (e) {
      console.log(e);

      setStatus({ error: true });
    }
  }

  const hasButton = !status.success && !status.error && !status.throttled;

  let color;
  let message;

  if (status.success) {
    color = "#333";
    message = "Thank you. We'll get back to you as soon as posible.";
  } else if (status.error) {
    color = "#FF5070";
    message = "Something went wrong. Please try again later.";
  } else {
    color = "#333";
    message =
      "You cannot send any more requests for a while. Please try again later.";
  }

  return (
    <ContactWrapper extraMargin={extraMargin}>
      <ContactCard ref={cardElement}>
        <ContactHeading>Didn’t find your dream job?</ContactHeading>
        <ContactFields>
          {fields.map((item, index) => {
            const { name, label } = item;

            const infoItem = info.filter((item) => item.name === name)[0];

            const {
              value,
              displayError,
              hasInteracted,
              invalidMessage,
            } = infoItem;

            return (
              <ContactRow key={index}>
                <ContactLabel>
                  <Typo25
                    color="#658AC8"
                    as="label"
                    fontWeight="500"
                    htmlFor={`contact-input-${name}`}
                  >
                    {label}
                  </Typo25>
                </ContactLabel>
                <ContactInputWrapper>
                  <ContactInput
                    id={`contact-input-${name}`}
                    name={name}
                    value={value}
                    onChange={(e) => onChange(name, e.currentTarget.value)}
                    onBlur={(e) => onBlur(name)}
                    onFocus={(e) => onFocus(name)}
                    error={hasInteracted && displayError}
                  />
                  {hasInteracted && displayError && (
                    <ContactError top={name === "email" || name === "url"}>
                      <Typo22 color="rgba(255,80,112,0.5)" lineHeight="26px">
                        {invalidMessage}
                      </Typo22>
                    </ContactError>
                  )}
                </ContactInputWrapper>
              </ContactRow>
            );
          })}
          {(() => {
            const infoItem = info.filter((item) => item.name === "message")[0];

            const {
              value,
              displayError,
              invalidMessage,
              hasInteracted,
            } = infoItem;

            return (
              <ContactRow full={true}>
                <ContactLabel>
                  <Typo25
                    color="#658AC8"
                    as="label"
                    fontWeight="500"
                    htmlFor={`contact-input-message`}
                  >
                    Your skills and what are you’re looking for *
                  </Typo25>
                </ContactLabel>
                <ContactInputWrapper>
                  <ContactTextarea
                    id={`contact-input-message`}
                    value={value}
                    onChange={(e) => onChange("message", e.target.value)}
                    onBlur={(e) => onBlur("message")}
                    onFocus={(e) => onFocus("message")}
                    error={hasInteracted && displayError}
                    data-gramm="false"
                    data-gramm_editor="false"
                    data-enable-grammarly="false"
                  />
                  {hasInteracted && displayError && (
                    <ContactError top={false}>
                      <Typo22 color="rgba(255,80,112,0.5)">
                        {invalidMessage}
                      </Typo22>
                    </ContactError>
                  )}
                </ContactInputWrapper>
              </ContactRow>
            );
          })()}
        </ContactFields>
        <ContactButtonWrapper>
          {hasButton ? (
            <ApplyButton
              big={true}
              disabled={status.loading || !valid}
              onClick={onSubmit}
              text="Send"
            />
          ) : (
            <ContactSuccess>
              <Typo14 color={color}>{message}</Typo14>
            </ContactSuccess>
          )}
        </ContactButtonWrapper>
      </ContactCard>
    </ContactWrapper>
  );
};

export default ContactForm;
