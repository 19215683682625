import React from "react";
import ICaseStudy from "../../interfaces/ICaseStudy";
import IClientSuccess from "../../interfaces/IClientSuccess";
import IJob from "../../interfaces/IJob";
import JobListControls from "../JobListControls";

import JobsListWithClients from "../JobsListWithClients";

interface IProps {
  jobs: IJob[];
  caseStudies: ICaseStudy[];
  clientSuccesses: IClientSuccess[];
  showAll: boolean;
}

export const JobsWithFilter: React.FC<IProps> = (props) => {
  const { caseStudies, clientSuccesses, showAll } = props;

  return (
    <>
      <JobListControls />
      <JobsListWithClients
        caseStudies={caseStudies}
        clientSuccesses={clientSuccesses}
        showAll={showAll}
      />
    </>
  );
};

export default JobsWithFilter;
