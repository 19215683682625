import React from "react";
import {
  JobListLoadingHeading,
  JobListLoadingImage,
  JobListLoadingItem,
  JobListLoadingWrapper,
} from "./styles";

interface Props {}

const JobListLoading: React.FC<Props> = (props) => {
  const {} = props;

  return (
    <JobListLoadingWrapper>
      {Array(5)
        .fill(1)
        .map((item: number, index: number) => {
          return (
            <JobListLoadingItem key={index}>
              <JobListLoadingHeading />
              <JobListLoadingImage />
            </JobListLoadingItem>
          );
        })}
    </JobListLoadingWrapper>
  );
};

export default JobListLoading;
