import React, { useContext, useEffect, useState } from "react";
import { JobsFilterContext } from "../../contexts/JobsFilterContext";
import useWindowSize from "../../hooks/windowSize";
import JobListControlsLoading from "../JobListControlsLoading";
import JobListFilters from "../JobListFilters";
import JobListSearch from "../JobListSearch";
import NarrowSection from "../NarrowSection";
import {
  JobListControlsOverlay,
  JobListControlsStyled,
  JobListSearchWrapper,
} from "./styles";

const JobListControls: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const { loading, queryEvaluated } = useContext(JobsFilterContext);

  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    if (!document.body) return;

    if (windowWidth > 1024) {
      document.body.style.overflow = "";

      return;
    }

    if (visible) {
      document.body.style.overflow = "hidden";
      return;
    }

    document.body.style.overflow = "";
  }, [visible, windowWidth]);

  if (loading && !queryEvaluated) {
    return (
      <NarrowSection>
        <JobListControlsLoading />
      </NarrowSection>
    );
  }

  return (
    <NarrowSection>
      <JobListControlsStyled>
        <JobListSearchWrapper>
          <JobListSearch />
        </JobListSearchWrapper>
        <JobListControlsOverlay
          show={visible}
          onClick={() => setVisible(false)}
        />
        <JobListFilters visible={visible} setVisible={setVisible} />
      </JobListControlsStyled>
    </NarrowSection>
  );
};

export default JobListControls;
