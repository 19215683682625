import styled from 'styled-components';

import { media } from '../../common/MediaQueries';

export const NarrowSectionStyled = styled.div`
  padding: 0 27px;

  @media ${media.tablet} {
    padding: 0 25px;
    max-width: 1354px;
    margin: 0 auto;
  }
`;
