import React from "react";
import IClientSuccess from "../../interfaces/IClientSuccess";
import StrapiImage from "../StrapiImage";

import { Heading15, Typo18 } from "../Typography";

import {
  Wrapper,
  Row,
  ImagePadding,
  ImageWrapper,
  TitlesWrapper,
  Text,
  Padding,
} from "./styled";

interface IProps {
  clientSuccess: IClientSuccess;
}

const ClientSuccess: React.FC<IProps> = ({ clientSuccess }) => {
  const { image, heading, text } = clientSuccess;

  return (
    <Padding>
      <Wrapper>
        <Row>
          <ImagePadding>
            <ImageWrapper>
              <StrapiImage image={image} />
            </ImageWrapper>
          </ImagePadding>
          <TitlesWrapper>
            <Heading15 color="#1B1B1B">{heading}</Heading15>
          </TitlesWrapper>
        </Row>
        <Text>
          <Typo18 color="#737F9D">{text}</Typo18>
        </Text>
      </Wrapper>
    </Padding>
  );
};

export default ClientSuccess;
