import React from "react";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";

const Wrapper = styled.span`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 14px;
  pointer-events: none;
  background: ${({ theme }) => theme.colors.watermelon};
  color: #fff;
  font-weight: 600;

  @media ${media.tablet} {
    width: 24px;
    height: 24px;
    background: #0b264c;
  }
`;

interface Props {
  activeFilters: number;
}

const ActiveFiltersBadge: React.FC<Props> = (props) => {
  const { activeFilters } = props;

  if (!activeFilters) return null;

  return (
    <Wrapper>
      <p>{activeFilters}</p>
    </Wrapper>
  );
};

export default ActiveFiltersBadge;
