import React from "react";
import { SvgComponent } from "./../../definitions/Svg";

const ScaleSmall: SvgComponent = (props) => {
  return (
    <svg
      width="284"
      height="5"
      viewBox="0 0 285 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M -284 3 H 578"
        stroke="currentColor"
        strokeWidth="5"
        strokeDasharray="1 10.83"
      />
    </svg>
  );
};

export default ScaleSmall;
