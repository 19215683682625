import React from "react";
import { SvgComponent } from "./../../definitions/Svg";

const ScaleBig: SvgComponent = (props) => {
  return (
    <svg
      width="284"
      height="12"
      viewBox="0 0 285 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M -284 6 H 578"
        stroke="currentColor"
        strokeWidth="12"
        strokeDasharray="1 46.33"
      />
    </svg>
  );
};

export default ScaleBig;
