import React from "react";
import aEvent from "../../common/aEvent";
import ICaseStudy from "../../interfaces/ICaseStudy";

import CaseStudySliderSmallItem from "../CaseStudySliderSmallItem";
import SimpleSlider from "../SimpleSlider";

interface IProps {
  caseStudies: ICaseStudy[];
  isJobs: boolean;
}

const CaseStudiesSliderSmall: React.FC<IProps> = ({ caseStudies, isJobs }) => {
  if (caseStudies.length === 0) return null;

  const first = caseStudies.slice(0, 3);

  return (
    <SimpleSlider
      interactionCallback={() => {
        aEvent({
          ec: "Slider Events",
          ea: "slider-interaction",
          el: "client-testemonials",
          ni: false,
        });
      }}
      padding={22}
      dots={!isJobs}
      paddingDesktop={16}
      formatItems={true}
      renderItem={(item: ICaseStudy, props: any) => {
        return (
          <CaseStudySliderSmallItem
            {...props}
            key={item.id}
            isCompact={false}
            caseStudy={item}
          />
        );
      }}
      items={first}
    />
  );
};

export default CaseStudiesSliderSmall;
