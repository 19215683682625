import styled, { css } from 'styled-components';
import { media } from '../../common/MediaQueries';

export const JobListFilterMobileButtonWrapper = styled.div`
  display: flex;

  @media ${media.tablet} {
    display: none;
  }
`;

interface I {
  background?: boolean;
  backgroundColor?: string;
  border?: boolean;
}

export const JobListFilterMobileButton = styled.button<I>`
  ${({ backgroundColor }) => backgroundColor && `background: ${backgroundColor};`}

  ${({ background }) => (background ? 'background:#fff;' : '')}

    border: ${({ theme, border }) => (border ? `1px solid ${theme.colors.sky}` : 'none')};

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  height: 46px;
  width: 86px;
  cursor: pointer;
  border-radius: 100px;
`;

export const JobSearchCloseImageWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.watermelon};

  & svg {
    width: 18px;
    height: 18px;
  }
`;

interface IJobListFilterMainRow {
  visible: boolean;
}

export const JobListFilterMainRow = styled.div<IJobListFilterMainRow>`
  position: absolute;
  top: calc(100% + 5px);
  padding: 2px 0 3px 0;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow:
    -4px 0px 22px rgba(0, 0, 0, 0.02),
    9px 0px 28px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  overflow: hidden;

  ${({ visible }) => css`
    ${!visible ? 'opacity: 0; pointer-events: none;' : ''}
  `};

  @media ${media.tablet} {
    display: flex;
    align-items: center;
    position: relative;
    opacity: 1;
    pointer-events: auto;
    top: 0;
    width: auto;
    background: #f7f7f7;
    border-radius: 100px;
    box-shadow: none;
    padding: 0;
    overflow: visible;
  }
`;

interface IJobListFilterWrapper {
  tabletWidth: number;
  desktopWidth: number;
}

export const JobListFilterWrapper = styled.div<IJobListFilterWrapper>`
  position: relative;

  @media ${media.tablet} {
    width: ${({ tabletWidth }) => tabletWidth}px;
  }

  @media ${media.desktop} {
    width: ${({ desktopWidth }) => desktopWidth}px;
  }
`;

interface IJobListFilterInk {
  showInk: boolean;
}

export const JobListFilterInk = styled.div<IJobListFilterInk>`
  @media ${media.tablet} {
    display: flex;
    align-items: center;
    border-radius: 100px;

    ${({ showInk }) => (showInk ? 'background: #EAEDF1;' : '')}
  }
`;

export const JobListFilterRoleTypeWrapper = styled.div`
  @media ${media.tablet} {
    width: 100%;
    background: #ffffff;
    box-shadow: 9px 0px 28px rgba(0, 0, 0, 0.08);
    border-radius: 100px;
  }
`;

interface IJobListFilterLine {
  transparent: boolean;
}

export const JobListFilterLine = styled.div<IJobListFilterLine>`
  display: none;

  @media ${media.tablet} {
    display: block;
    height: 46px;
    width: 1px;
    flex-shrink: 0;

    background: ${({ transparent }) => (transparent ? 'transparent' : '#E5E5E5')};
  }
`;

interface JobListButtonWrapperProps {
  show?: boolean;
}

export const JobListButtonWrapper = styled.div<JobListButtonWrapperProps>`
  ${({ show }) => (show ? 'display: flex;' : 'display: none;')}
  justify-content: center;
  align-items: center;
  border-top: 1px solid #dedede;
  padding-bottom: 20px;
  padding-top: 20px;

  @media ${media.tablet} {
    // display: block;
    display: none;
    padding: 16px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 30px;
  }
`;

export const JobListButton = styled.button`
  position: relative;
  min-width: 152px;
  border: none;
  background: ${({ theme }) => theme.colors.watermelon};
  border-radius: 32px;
  padding: 7px 24px;
  cursor: pointer;

  & > p > span:nth-child(2) {
    display: none;
  }

  &:hover,
  &:focus,
  &:active {
    background: ${({ theme }) => theme.colors.salmon};
  }

  @media ${media.tablet} {
    min-width: 152px;
    padding: 14px 21px 15px 21px;
    border-radius: 63px;

    & > p > span:nth-child(1) {
      display: none;
    }

    & > p > span:nth-child(2) {
      display: inline;
    }
  }
`;

export const ActiveFiltersWrapper = styled.span`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  transform: translate(25%, -25%);
`;

export const ActiveFiltersWrapperDesktop = styled.span`
  display: none;

  @media ${media.tablet} {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    transform: translate(25%, -25%);
  }
`;
