import React, { ReactElement, useContext, useState } from "react";
import { JobsFilterContext } from "../../contexts/JobsFilterContext";
import useUpdate from "../../hooks/useUpdate";
import { StatelessSearchInput } from "../SearchInput";

export default function JobListSearch(): ReactElement {
  const [search, setSearch] = useState("");

  const { setJobs, initialJobs, searched, setSearched } = useContext(
    JobsFilterContext
  );

  useUpdate(() => {
    if (search === "") {
      setJobs([...initialJobs]);
      setSearched(false);

      return;
    }

    const searchFormatted = search.toLowerCase().replace(/\s\s+/g, " ").trim();

    const filteredJobs = initialJobs.filter((job) => {
      const nameMatch = job.name.toLowerCase().includes(searchFormatted);
      const mustHaveTechMatch =
        job.mustHaveTechItems &&
        job.mustHaveTechItems.some((tech) =>
          tech.name.toLowerCase().includes(searchFormatted)
        );
      const niceToHaveTechMatch =
        job.niceToHaveTechItems &&
        job.niceToHaveTechItems.some((tech) =>
          tech.name.toLowerCase().includes(searchFormatted)
        );

      return nameMatch || mustHaveTechMatch || niceToHaveTechMatch;
    });

    setSearched(true);
    setJobs(filteredJobs);
  }, [search]);

  useUpdate(() => {
    if (searched) return;

    setSearch("");
  }, [searched]);

  return (
    <StatelessSearchInput
      onChange={(value: string) => setSearch(value)}
      value={search}
    />
  );
}
