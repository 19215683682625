import React from "react";
import styled from "styled-components";
import { media } from "../../common/MediaQueries";

const Wrapper = styled.div`
  margin-top: 25px;
  display: flex;
  width: 100%;
  justify-content: center;

  @media ${media.tablet} {
    margin-top: 20px;
  }
`;

const Search = styled.div`
  flex-grow: 1;
  background-color: #f7f7f7;
  border: none;
  border-radius: 100px;
  height: 46px;

  @media ${media.tablet} {
    display: none;
  }
`;

const FilterButtonMobile = styled.div`
  margin-left: 4px;
  border: none;
  height: 46px;
  width: 86px;
  border-radius: 100px;
  background-color: #f7f7f7;

  @media ${media.tablet} {
    display: none;
  }
`;

const DesktopFilter = styled.div`
  display: none;

  @media ${media.tablet} {
    display: flex;
    width: 731px;
    height: 83px;
    background: #f7f7f7;
    border-radius: 100px;
  }

  @media ${media.desktop} {
    width: 801px;
  }
`;

const DesktopButton = styled.div`
  display: none;

  @media ${media.tablet} {
    display: block;
    background: #fcfcfc;
    border-radius: 100px;
    width: 238px;
    height: 100%;
  }

  @media ${media.desktop} {
    width: 258px;
  }
`;

const JobListControlsLoading: React.FC = (props) => {
  return (
    <Wrapper>
      <Search />
      <FilterButtonMobile />
      <DesktopFilter>
        <DesktopButton />
      </DesktopFilter>
    </Wrapper>
  );
};

export default JobListControlsLoading;
