import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import aEvent from "../../common/aEvent";
import numberToGMT from "../../common/numberToGMT";
import FilterCardTopRow from "../FilterCardTopRow";
import FilterSave from "../FilterSave";
import Scale from "../Scale";
import { Typo14 } from "../Typography";
import {
  TimezoneFilterArrow,
  TimezoneFilterBox,
  TimezoneFilterBoxes,
  TimezoneFilterText,
  TimezoneFilterTo,
  TimezoneFilterWrapper,
  TimezoneFitlerContentWrapper,
  TimezoneFitlerScaleWrapper,
  TimezoneSelectCurrent,
} from "./styles";

interface Props {
  show: boolean;
  setOpenTab: React.Dispatch<React.SetStateAction<string | null>>;
  onBackArrowClick: MouseEventHandler<HTMLButtonElement>;
  label: string;
  timezone: number | null;
  onChange: Function;
  onConfirm: Function;
  onScaleTouchEnd: Function;
  onSelectMyTimezoneClick: Function;
}

const TimezoneSimple: React.FC<Props> = (props) => {
  const [offset, setOffset] = useState<number | null>(null);

  const {
    show,
    onBackArrowClick,
    label,
    timezone,
    onChange,
    onConfirm,
    onScaleTouchEnd,
    onSelectMyTimezoneClick,
  } = props;

  useEffect(() => {
    const newOffset = -1 * Math.floor(new Date().getTimezoneOffset() / 60);

    setOffset(newOffset);
  }, []);

  function onCurrentSelect() {
    if (!offset) return;

    aEvent({
      ec: "Time Filter Events",
      ea: "timezone-filter",
      el: "select-my-timezone-button-use",
      ni: false,
    });

    onSelectMyTimezoneClick(offset);
  }

  const onScaleChange = (newTimezone: number) => {
    onChange(newTimezone);

    aEvent({
      ec: "Time Filter Events",
      ea: "timezone-filter",
      el: "timezone-graph-use",
      ni: false,
    });
  };

  return (
    <TimezoneFilterWrapper show={show}>
      <TimezoneFilterArrow />
      <FilterCardTopRow onClose={onBackArrowClick} placeholder={label} />
      <TimezoneFitlerContentWrapper>
        <TimezoneSelectCurrent onClick={onCurrentSelect}>
          <Typo14>
            {`Select my timezone${
              offset !== null && ` | ${numberToGMT(offset)}`
            }`}
          </Typo14>
        </TimezoneSelectCurrent>
        <TimezoneFitlerScaleWrapper>
          <Scale
            timezone={timezone}
            hintTimezone={offset}
            show={show}
            onChange={onScaleChange}
            onEnd={onScaleTouchEnd}
          />
        </TimezoneFitlerScaleWrapper>
        <TimezoneFilterBoxes>
          <TimezoneFilterText>
            <Typo14 color="#0B264C">Selected GMT:</Typo14>
          </TimezoneFilterText>
          <TimezoneFilterBox>
            {timezone !== null
              ? `${timezone > 0 ? "+" : ""}${timezone}`
              : "-12"}
          </TimezoneFilterBox>
          {timezone === null && (
            <>
              <TimezoneFilterTo>to</TimezoneFilterTo>
              <TimezoneFilterBox>+12</TimezoneFilterBox>
            </>
          )}
        </TimezoneFilterBoxes>
        <FilterSave onSave={onConfirm} />
      </TimezoneFitlerContentWrapper>
    </TimezoneFilterWrapper>
  );
};

export default TimezoneSimple;
