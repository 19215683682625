import React, { MouseEventHandler, ReactElement } from "react";
import { Typo14, Typo21 } from "../Typography";
import {
  FilterButtonWrapper,
  FilterButtonPlaceholder,
  FilterButton,
  FilterButtonInk,
  FilterButtonCloseButton,
  FilterButtonGroup,
} from "./styles";

interface Props {
  label: string;
  summary: string;
  border: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  onCloseClick: MouseEventHandler<HTMLButtonElement>;
  show: boolean;
  showInk: boolean;
  showDiscard?: boolean;
  discardBackgorund?: string;
  desktopPaddingLeft: number;
  tabletPaddingLeft: number;
}

export default function JobListFilterButton(props: Props): ReactElement {
  const {
    label,
    summary,
    border,
    show,
    showInk,
    onClick,
    onCloseClick,
    desktopPaddingLeft,
    showDiscard,
    discardBackgorund,
    tabletPaddingLeft,
  } = props;

  return (
    <FilterButtonGroup>
      <FilterButtonInk show={showInk} />
      <FilterButton
        show={show}
        onClick={onClick}
        tabletPaddingLeft={tabletPaddingLeft}
        desktopPaddingLeft={desktopPaddingLeft}
      >
        <FilterButtonWrapper border={border}>
          <Typo21 color="#658AC8" letterSpacing="-0.04em">
            {label}
          </Typo21>
          <FilterButtonPlaceholder>
            <Typo14>{summary}</Typo14>
          </FilterButtonPlaceholder>
        </FilterButtonWrapper>
      </FilterButton>
      <FilterButtonCloseButton
        show={showDiscard}
        onClick={onCloseClick}
        discardBackgorund={discardBackgorund}
      />
    </FilterButtonGroup>
  );
}
