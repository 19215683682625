import React from "react";
import { navigate } from "gatsby";
import StrapiImage from "../StrapiImage";
import { Typo15, Typo19, Typo28 } from "../Typography";
import {
  Padding,
  ImageWrapper,
  Position,
  Row,
  TitlesWrapper,
  Wrapper,
  Quote,
  Dashes,
  Title,
  Comma,
  NameDesktop,
  NameMobile,
} from "./styles";
import ICaseStudy from "../../interfaces/ICaseStudy";

interface IProps {
  caseStudy: ICaseStudy;
  isCompact?: boolean;
}

const CaseStudySliderSmallItem: React.FC<IProps> = ({
  caseStudy,
  isCompact,
}) => {
  const { slug, sliderImage, heading, leadQuote, companyName } = caseStudy;

  const trimmedCompanyName =
    companyName && companyName.length > 8
      ? `${companyName.substring(0, 5)}...`
      : companyName;

  return (
    <Padding isCompact={isCompact}>
      <Wrapper
        isLink={slug ? true : false}
        onClick={slug ? (e) => navigate(`/success-story/${slug}/`) : undefined}
      >
        <Row isCompact={isCompact}>
          <ImageWrapper isCompact={isCompact}>
            <StrapiImage image={sliderImage} />
          </ImageWrapper>
          <TitlesWrapper isCompact={isCompact}>
            <Title isCompact={isCompact}>
              <Typo15 as="h3" fontWeight={isCompact ? "500" : "400"}>
                <Dashes>— </Dashes>
                {heading}
              </Typo15>
            </Title>
            <Comma isCompact={isCompact}>{", "}</Comma>
            <Position isCompact={isCompact}>
              <Typo15 color="#737F9D" textAlign={isCompact ? "center" : ""}>
                <NameDesktop>
                  {companyName ? `HIRED BY ${companyName}` : ""}
                </NameDesktop>
                <NameMobile>
                  {trimmedCompanyName ? `HIRED BY ${trimmedCompanyName}` : ""}
                </NameMobile>
              </Typo15>
            </Position>
          </TitlesWrapper>
          <Quote isCompact={isCompact}>
            {isCompact ? (
              <Typo28 color="#1B1B1B">{leadQuote}</Typo28>
            ) : (
              <Typo19 color="#1B1B1B">{leadQuote}</Typo19>
            )}
          </Quote>
        </Row>
      </Wrapper>
    </Padding>
  );
};

export default CaseStudySliderSmallItem;
