import IJob from "../interfaces/IJob";

export default function intersectJobs(lists: IJob[][]) {
  return lists.reduce((acc, list, index) => {
    if (index === 0) return list;

    return acc.filter(
      (job) => list.filter((listJob) => listJob.id === job.id)[0]
    );
  }, [] as IJob[]);
}
