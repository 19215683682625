import React from "react";
import { SvgComponent } from "./../../definitions/Svg";

const ScaleDesktop: SvgComponent = (props) => {
  return (
    <svg
      width="497"
      height="8"
      viewBox="0 0 498 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M -497 4 H 994"
        stroke="currentColor"
        strokeWidth="8"
        strokeDasharray="1 19.7"
      />
    </svg>
  );
};

export default ScaleDesktop;
